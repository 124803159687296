<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <vx-card ref="filterCard" v-bind:title="$t('Filters')" class="user-list-filters mb-8" collapse-action>
      <div class="vx-row">
        <!-- CountryID -->
        <div class="vx-col md:w-1/3 sm:w-1/2 w-full mb-4">
          <label class="text-sm opacity-75">{{ $t("Country") }}</label>
          <v-select label="Name" class="w-full" v-model="PatientSearch.CountryID" :options="countries"
            :reduce="(ID) => ID.ID" />
        </div>
        <!-- PatientName -->
        <div class="vx-col md:w-1/3 sm:w-1/2 w-full mb-4">
          <label class="text-sm opacity-75">{{ $t("Name") }}</label>
          <vs-input v-model="PatientSearch.PatientName" class="w-full" name="DoctorName" />
        </div>
        <!-- DoctorAssignedID -->
        <div class="vx-col md:w-1/3 sm:w-1/2 w-full mb-4">
          <label class="text-sm opacity-75">{{ $t("Doctor assigned") }}</label>
          <v-select label="Name" class="w-full" v-model="PatientSearch.DoctorAssignedID" :options="doctors"
            :reduce="(ID) => ID.ID" />
        </div>
        <!-- AssignedPatientAgentID -->
        <div class="vx-col md:w-1/3 sm:w-1/2 w-full mb-4" v-if="$acl.check('admin')">
          <label class="text-sm opacity-75">{{ $t("User access") }}</label>
          <v-select label="UserName" class="w-full" v-model="PatientSearch.AssignedPatientAgentID" :options="users"
            :reduce="(ID) => ID.Id" />
        </div>
        <!-- AppointmentDateFrom -->
        <div class="vx-col md:w-1/3 sm:w-1/2 w-full mb-4">
          <label class="text-sm opacity-75">{{
            $t("Online appointments Date From")
          }}</label>
          <datepicker placeholder="from" v-model="PatientSearch.AppointmentDateFrom" class="vx-col w-full" name="from">
          </datepicker>
        </div>
        <!-- AppointmentDateTo -->
        <div class="vx-col md:w-1/3 sm:w-1/2 w-full mb-4">
          <label class="text-sm opacity-75">{{
            $t("Online appointments Date To")
          }}</label>
          <datepicker placeholder="from" v-model="PatientSearch.AppointmentDateTo" class="vx-col w-full" name="from">
          </datepicker>
        </div>
        <!-- SurgeryDateFrom -->
        <!-- <div class="vx-col md:w-1/3 sm:w-1/2 w-full mb-4">
          <label class="text-sm opacity-75">{{
            $t("Surgeries & treatments Date From")
          }}</label>
          <datepicker placeholder="from" v-model="PatientSearch.SurgeryDateFrom" class="vx-col w-full" name="from">
          </datepicker>
        </div> -->
        <!-- SurgeryDateTo -->
        <!-- <div class="vx-col md:w-1/3 sm:w-1/2 w-full mb-4">
          <label class="text-sm opacity-75">{{
            $t("Surgeries & treatments Date To")
          }}</label>
          <datepicker placeholder="from" v-model="PatientSearch.SurgeryDateTo" class="vx-col w-full" name="from">
          </datepicker>
        </div> -->
        <!-- HavePendingTask -->
        <div class="vx-row md:w-1/3 sm:w-1/2 w-full mb-4">
          <div class="vx-col lg:w-1/2">
            <label>Pending tasks </label>
            <vs-checkbox color="success" class="mt-8" v-model="PatientSearch.HavePendingTask"></vs-checkbox>
          </div>
          <!-- HavePendingPayment -->
          <div class="vx-col lg:w-1/2">
            <label>Pending payment </label>
            <vs-checkbox color="success" class="mt-8" v-model="PatientSearch.HavePendingPayment"></vs-checkbox>
          </div>
        </div>
        <div class="vx-col w-full">
          <vs-button class="my-6" @click="searchDataFirst">{{
            $t("Search")
          }}</vs-button>
          <vs-button class="ml-6" @click="ResetSearch()">{{ $t("Reset") }}</vs-button>
        </div>
      </div>
    </vx-card>
    <div class="vx-row">
      <div class="vx-col w-full md:w-1/3 sm:w-1/2 mb-base" v-for="item in patients" :key="item.ID"
        @click="$router.push(item.url).catch(() => { })">
        <vx-card class="vxCardPatient text-center cursor-pointer rounded-xl">
          <div class="vx-row ml-3 mr-3" style="justify-content: right">
            <feather-icon icon="TrashIcon" svgClasses="w-7 h-7 hover:text-primary stroke-current"
              @click="openConfirm(item)" />
          </div>
          <div class="vx-row">
            <imageLazy :imageStyle="{
              'border-radius': '40px',
              width: '80px',
              height: '80px',
              size: '100px',
            }" :imageClass="'ml-5'" :src="baseURL + item.ProfileImagePath" placeHolderType="patient" />
            <div class="vx-col mb-3 mt-3 m-3">
              <h1 class="mb-2">{{ item.Name }}</h1>
              <u @click="editData(item.ID)">{{ "Profile Data" }}</u>
              <!-- <u><p>Profile Data"</p></u> -->
            </div>
          </div>
          <div class="vx-col"></div>
          <div class="vx-row ml-3">
            <div class="lg:w-1/2 w-full">
              <vs-button class="mr-2 customizer-btn rounded-lg text-sm" color="#0D66D0" type="filled" style="width: 95%"
                @click="getMedicalHistory(item)">{{ $t("Medical History") }}</vs-button>
            </div>

            <div class="lg:w-1/2 w-full">
              <vs-button class="mr-2 customizer-btn rounded-lg text-sm" color="#0D66D0" style="width: 95%"
                @click="getReports(item)">{{ $t("Reports") }}</vs-button>
            </div>
          </div>

          <div class="vx-row ml-3" style="margin-top: 3%">
            <div class="lg:w-1/2 w-full">
              <vs-button class="mr-2 customizer-btn rounded-lg text-sm" color="#009CFF" type="filled" style="width: 95%"
                @click="getAppointments(item)">{{ $t("Appointments") }}</vs-button>
            </div>

            <div class="lg:w-1/2 w-full">
              <vs-button class="mr-2 customizer-btn rounded-lg text-sm" color="#009CFF" style="width: 95%"
                @click="getPendingPayments(item)">{{ $t("Pending Payments") }}</vs-button>
            </div>
          </div>
          <!-- <div class="vx-row ml-3" style="margin-top: 3%">
            <div class="w-full">
              <vs-button class="mr-2 customizer-btn rounded-lg text-sm" color="#0D66D0" style="width: 95%"
                @click="getSurgeriesTreatments(item)">{{ $t("Surgeries & Treatments") }}</vs-button>
            </div>
          </div> -->
          <div class="vx-row ml-3" style="margin-top: 3%">
            <div class="w-full">
              <vs-button class="mr-2 customizer-btn rounded-lg text-sm" color="#009CFF" style="width: 95%"
                @click="getPendingMedicalDocumentation(item)">{{ $t("Pending Medical Documentation") }}</vs-button>
            </div>
          </div>
        </vx-card>
      </div>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import moduleCountry from "@/store/settings/country/moduleCountry.js";
import modulePatient from "@/store/Patient/modulePatient.js";
import vSelect from "vue-select";
import { domain } from "@/gloabelConstant.js";
import moduleDoctor from "@/store/doctor/moduleDoctor.js";
import Datepicker from "vuejs-datepicker";
import moduleUser from "@/store/user/moduleUser.js";
import imageLazy from "@/components/image_lazy.vue";

export default {
  components: {
    vSelect,
    Datepicker,
    imageLazy,
  },
  data() {
    return {
      search: {
        IsPaging: true,
        PageNumber: 1,
        PageSize: 20,
      },
      baseURL: domain,
      Specialties: [],
      selected: [],
      showSearchSidebar: false,
      itemsPerPage: 10,
      isMounted: false,
      first: true,
      // Data Sidebar
      addNewDataSidebar: false,
      sidebarData: {},
      rowDataForDelete: {},
      activeConfirm: false,
      searchDoctor: {
        SmallSearch: true
      },
    };
  },
  computed: {
    PatientSearch() {
      debugger;
      return this.$store.state.patientList.PatientSearchObj;
    },
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    countries() {
      return this.$store.state.CountryList.countries;
    },
    patients() {
      return this.$store.state.patientList.patients;
    },
    doctors() {
      return this.$store.state.DoctorList.doctors;
    },
    users() {
      return this.$store.state.UserList.users;
    },
  },

  methods: {
    ResetSearch() {
      this.$store.state.patientList.PatientSearchObj = {};
      this.PatientSearch = {};
      this.PatientSearch.PageNumber = 1;
      this.PatientSearch.IsPaging = true;
      this.PatientSearch.PageSize = 20;
      this.searchData();
    },
    doctorSearch() {
      this.$vs.loading();

      this.$store.dispatch("DoctorList/GetAllDoctors", this.searchDoctor).then(() => {

      });

    },

    addNewData() {
      this.$router.push({ name: "PatientEdit" });
    },
    editData(ID) {
      this.$router.push({ name: "PatientEdit", params: { ID: ID } });
    },
    getMedicalHistory(data) {
      this.$router.push({
        name: "PatientMedicalHistory",
        params: { ID: data.ID },
      });
    },
    getReports(data) {
      this.$router.push({
        name: "PatientMedicalHistoryLists",
        params: { ID: data.ID },
      });
    },
    getAppointments(data) {
      this.$router.push({
        name: "ReservationSession",
        params: {
          ID: 0,
          PatientID: data.ID,
          IsAppointment: true,
        },
      });
    },
    getPendingPayments(data) {
      this.$router.push({
        name: "PendingPayment",
        params: {
          ID: data.ID,
        },
      });
    },
    getPendingMedicalDocumentation(data) {
      this.$router.push({
        name: "PendingMedicalDocumentation",
        params: {
          ID: data.ID,
        },
      });
    },
    getSurgeriesTreatments(data) {
      this.$router.push({
        name: "PatientReservationSurgery",
        params: {
          ID: data.ID,
        },
      });
    },
    deleteData() {
      debugger;
      this.$vs.loading();
      this.$store
        .dispatch("patientList/DeletePatient", this.rowDataForDelete.ID)
        .then(() => {
          this.$vs.loading.close();
          window.showDeleteSuccess();
          //this.searchData();
        })
        .catch((err) => {
          if (err.response.status == 400) {
            this.$vs.loading.close();
            window.showDeleteFailed(err.response.data.message);
          } else {
            this.$vs.loading.close();
            window.showDeleteFailed("");
          }
        });
      //this.$store.dispatch("patientList/SearchPatientsPaging", this.PatientSearch);
    },

    openConfirm(data) {
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: this.$t("Confirm"),
        text: this.$t("DeleteConfirmText"),
        accept: this.deleteData,
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel"),
      });
    },
    searchData() {
      this.$store
        .dispatch("patientList/SearchPatientsPaging", this.PatientSearch)
        .then(() => {
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
      if (
        document.documentElement.scrollHeight <= window.innerHeight &&
        res.data.Data.length >= this.PatientSearch.PageSize
      ) {
        this.PatientSearch.PageNumber++;
        this.searchData();
      }
    },
    searchDataFirst() {
      this.PatientSearch.PageNumber = 1;
      this.searchData();
    },
  },
  destroyed() {
    window.onscroll = () => { };
  },
  created() {
    if (!moduleUser.isRegistered) {
      this.$store.registerModule("UserList", moduleUser);
      moduleUser.isRegistered = true;
    }
    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }
    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
    if (!moduleCountry.isRegistered) {
      this.$store.registerModule("CountryList", moduleCountry);
      moduleCountry.isRegistered = true;
    }
    if (this.countries.length == 0)
      this.$store.dispatch("CountryList/GetAllCountries");
    this.$vs.loading();
    var userSearch = {};
    userSearch.UserType = "PatientServiceAgent";

    this.$store.dispatch("UserList/UserSearch", userSearch).then(() => {
      this.$vs.loading.close();
    });

    if (this.doctors.length == 0)
      this.doctorSearch();
    this.ResetSearch();
  },
  mounted() {
    window.onscroll = () => {
      let bottomOfWindow =
        document.documentElement.scrollTop + window.innerHeight + 80 >=
        document.documentElement.scrollHeight;
      debugger;
      if (bottomOfWindow) {
        if (this.patients.length > 0) {
          this.PatientSearch.PageNumber++;
          this.searchData();
        }
      }
    };
  },
};
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      >span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);

        td {
          padding: 20px;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
